import React from 'react';
import { Button, Divider, Stack, Text } from '@mantine/core';
import { mq } from '../config/theme';

const ContactUsView = () => {
  return (
    <Stack sx={{ gap: 0, flex: 1 }}>
      <Stack
        sx={{
          minHeight: 350,
          backgroundColor: '#262626',
          justifyContent: 'center',
          placeItems: 'center',
          textAlign: 'center'
        }}
      >
        <Text color={'#FFF'} size={35} weight={500}>
          Contact Us
        </Text>
      </Stack>
      <Stack
        sx={{
          flex: 1,
          backgroundColor: '#FFF',
          padding: '40px 20px',
          placeItems: 'center'
        }}
      >
        <Stack
          sx={{
            width: '100%',
            maxWidth: 600,
            textAlign: 'center',
            gap: 30,
            justifyContent: 'center',
            placeItems: 'center'
          }}
        >
          <Stack>
            <Text size={18} weight={700}>
              Your Queries are Valuable
            </Text>
            <Text>
              Daniel Timothy Leads is currently underdevelopment and is planned
              to be released summer of 2024. Please contact us for any inquiries
              you may have.
            </Text>
          </Stack>
          <Divider sx={{ width: '100%' }} />
          <Button
            component="a"
            href="mailto:info@danieltimothyleads.com"
            sx={mq({
              width: ['100%', '100%', 200],
              backgroundColor: '#ffdb4d',
              '&:hover': {
                backgroundColor: '#e6c545'
              },
              textTransform: 'uppercase',
              color: '#000',
              boxShadow: '1px 6px 12px 0px #828581',
              fontSize: 16,
              paddingLeft: 45,
              paddingRight: 45
            })}
          >
            Send Email
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ContactUsView;
