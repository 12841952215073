import React from 'react';
import { AppShell, Box, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import Footer from './components/content/navigation/Footer';
import Header from './components/content/navigation/Header';
import GlobalStyles from './config/GlobalStyles';
import { theme } from './config/theme';
import AboutView from './views/AboutView';
import ContactUsView from './views/ContactUsView';
import HomeView from './views/HomeView';
import PricingView from './views/PricingView';

const App = () => {
  return (
    <AppShell
      footer={<Footer />}
      header={<Header />}
      padding={0}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          flex: 1
        }}
      >
        <Routes>
          <Route element={<HomeView />} path="/" />
          <Route element={<PricingView />} path="/pricing" />
          <Route element={<ContactUsView />} path="/contact-us" />
          <Route element={<AboutView />} path="/about" />
          <Route element={<Navigate replace to="/" />} path="*" />
        </Routes>
      </Box>
    </AppShell>
  );
};

export default () => (
  <MantineProvider theme={theme}>
    <Notifications position="top-right" />
    <GlobalStyles />
    <Router>
      <App />
    </Router>
  </MantineProvider>
);
