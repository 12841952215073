import React from 'react';
import { Button, Divider, Group, Image, Stack, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Clock, DeviceDesktopAnalytics, Template } from 'tabler-icons-react';
import { mq } from '../config/theme';
import homeImage from '../images/dtleads-home-img.png';
import wavyBackground from '../images/saas-img.svg';
import video from '../videos/main_video_nosound.mp4';

const HomeView = () => {
  return (
    <Stack sx={{ gap: 0 }}>
      <Stack
        sx={mq({
          minHeight: [650, 750, 900],
          backgroundColor: '#262626',
          position: 'relative'
        })}
      >
        <Stack
          sx={{
            position: 'absolute',
            bottom: 0,
            flex: 1,
            width: '100%'
          }}
        >
          <Image src={wavyBackground} />
        </Stack>

        <Stack
          sx={mq({
            position: 'absolute',
            minHeight: 40,
            width: '100%',
            flex: 1,
            top: [0, 0, 80]
          })}
        >
          <Stack
            sx={{
              padding: 20,
              flex: 1,
              alignSelf: 'center',
              gap: 50,
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <Text
              color="#FFF"
              size={38}
              sx={mq({ display: ['none', 'flex'], lineHeight: '42px' })}
            >
              Daniel Timothy Leads - Outsource the Grind to Automation
            </Text>
            <Text
              color="#FFF"
              size={38}
              sx={mq({ display: ['flex', 'none'], lineHeight: '42px' })}
            >
              Daniel Timothy Leads
            </Text>
            <Group sx={mq({ gap: 40, flexDirection: ['column', 'row'] })}>
              <Button
                color="yellow"
                component={Link}
                size="lg"
                sx={{
                  backgroundColor: '#ffdb4d',
                  '&:hover': {
                    backgroundColor: '#e6c545'
                  },
                  textTransform: 'uppercase',
                  color: '#000',
                  boxShadow: '1px 6px 12px 0px #828581',
                  fontSize: 16,
                  paddingLeft: 45,
                  paddingRight: 45
                }}
                to="/contact-us"
              >
                14 Day Free Trial
              </Button>
              <Button
                component={Link}
                size="lg"
                sx={{
                  color: '#FFF',
                  border: 'solid 1px #FFF',
                  paddingLeft: 45,
                  paddingRight: 45
                }}
                to="/contact-us"
                variant="outline"
              >
                Contact Us
              </Button>
            </Group>
            <Stack
              sx={mq({
                paddingTop: 5
              })}
            >
              <video
                autoPlay
                muted
                style={{ width: '100%', maxWidth: 525 }}
                width="525"
              >
                <source src={video} type="video/mp4" />
              </video>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        sx={{
          backgroundColor: '#f8f9fa',
          padding: '100px 20px'
        }}
      >
        <Group
          sx={mq({
            alignSelf: 'center',
            width: '100%',
            maxWidth: 1100,
            flexDirection: ['column', 'column', 'row'],
            gap: [40, 40, 20]
          })}
        >
          <Stack sx={{ flex: 1 }}>
            <Image
              src={homeImage}
              styles={{
                imageWrapper: mq({
                  width: '100%',
                  maxWidth: [300, 400, '100%']
                })
              }}
            />
          </Stack>
          <Stack
            sx={mq({
              flex: 1,
              gap: 40,
              paddingLeft: [0, 0, 100],
              flexDirection: ['column', 'row', 'column'],
              alignItems: ['center', 'center', 'start']
            })}
          >
            <Stack sx={{ gap: 10 }}>
              <Text size={24} weight={700}>
                What is Daniel Timothy Leads?
              </Text>
              <Text weight={500}>
                Sales marketing platform proven to generate up to 10x the amount
                of potential leads vs manually emailing.
              </Text>
            </Stack>
            <Button
              color="yellow"
              component={Link}
              size="lg"
              sx={mq({
                backgroundColor: '#ffdb4d',
                '&:hover': {
                  backgroundColor: '#e6c545'
                },
                textTransform: 'uppercase',
                color: '#000',
                boxShadow: '1px 6px 12px 0px #828581',
                fontSize: 16,
                paddingLeft: 45,
                paddingRight: 45,
                width: '100%',
                maxWidth: [350, 200]
              })}
              to="/contact-us"
            >
              Get Started
            </Button>
          </Stack>
        </Group>
      </Stack>
      <Stack
        sx={mq({
          backgroundColor: '#FFF',
          padding: ['60px 20px', '60px 20px', '100px 20px']
        })}
      >
        <Stack
          sx={mq({
            alignSelf: 'center',
            width: '100%',
            maxWidth: 1100,
            gap: [60, 60, 100]
          })}
        >
          <Stack
            sx={{
              alignSelf: 'center',
              textAlign: 'center',
              maxWidth: 800,
              gap: 20
            }}
          >
            <Text size={24} weight={700}>
              OUR PLATFORM
            </Text>
            <Text size={16}>
              Daniel Timothy Leads (DT Leads) simplifies generating leads via
              email. DT Leads allows users to send scheduled emails and follows
              ups while staying organized and aware of their KPIs. Adding DT to
              current systems is seamless with its ability to integrate with
              CRMs such as HubSpot, Saleforce (and 100+ more platforms). Daniel
              Timothy adds power to sales teams that never thought they could be
              more productive.
            </Text>
          </Stack>
          <Divider />
          <Group
            sx={mq({
              justifyContent: 'space-between',
              alignItems: ['center', 'center', 'start'],
              gap: [60, 60, 40],
              flexDirection: ['column', 'column', 'row']
            })}
          >
            <Stack
              sx={mq({
                flex: 1,
                textAlign: 'center',
                justifyContent: 'center',
                placeItems: 'center',
                gap: [5, 5, 20]
              })}
            >
              <Clock color="#ffdb4d" size={50} />
              <Text weight={700}>Keep it simple</Text>
              <Text color="grey">
                Engage with customers to grow relationships using automated
                tools
              </Text>
            </Stack>

            <Stack
              sx={mq({
                flex: 1,
                textAlign: 'center',
                justifyContent: 'center',
                placeItems: 'center',
                gap: [5, 5, 20]
              })}
            >
              <Template color="#ffdb4d" size={50} />
              <Text weight={700}>
                Integrate with Outlook to Preschedule Sales Campaign
              </Text>
              <Text color="grey">
                Plan who you want to engage with and plan accordingly in advance
                with our Sales Enablement platform
              </Text>
            </Stack>

            <Stack
              sx={mq({
                flex: 1,
                textAlign: 'center',
                justifyContent: 'center',
                placeItems: 'center',
                gap: [5, 5, 20]
              })}
            >
              <DeviceDesktopAnalytics color="#ffdb4d" size={50} />
              <Text weight={700}>
                Mointor Sales Funnel through dashboard or CRM Intergations
              </Text>
              <Text color="grey">
                Use Manager Dashboard to mointor activity, or seamlessly
                intergrate DTL with Salesforce, HubSpot or many other CRM
                platforms
              </Text>
            </Stack>
          </Group>
          <Divider />
          <Group
            sx={mq({
              justifyContent: 'space-between',
              alignItems: ['center', 'start'],
              gap: 40,
              flexDirection: ['column', 'row']
            })}
          >
            <Stack
              sx={{
                flex: 1,
                textAlign: 'center',
                justifyContent: 'center',
                placeItems: 'center',
                gap: 5,
                textTransform: 'uppercase'
              }}
            >
              <Text size={30} weight={700}>
                100%
              </Text>
              <Text>Increase in leads generated</Text>
            </Stack>

            <Stack
              sx={{
                flex: 1,
                textAlign: 'center',
                justifyContent: 'center',
                placeItems: 'center',
                gap: 5,
                textTransform: 'uppercase'
              }}
            >
              <Text size={30} weight={700}>
                100%
              </Text>
              <Text>Lift in ROI</Text>
            </Stack>

            <Stack
              sx={{
                flex: 1,
                textAlign: 'center',
                justifyContent: 'center',
                placeItems: 'center',
                gap: 5,
                textTransform: 'uppercase'
              }}
            >
              <Text size={30} weight={700}>
                10X
              </Text>
              <Text>More conversions</Text>
            </Stack>

            <Stack
              sx={{
                flex: 1,
                textAlign: 'center',
                justifyContent: 'center',
                placeItems: 'center',
                gap: 5,
                textTransform: 'uppercase'
              }}
            >
              <Text size={30} weight={700}>
                100%
              </Text>
              <Text>Increase in productivity</Text>
            </Stack>
          </Group>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HomeView;
