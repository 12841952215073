import React from 'react';
import { Divider, Stack, Text } from '@mantine/core';

const AboutView = () => {
  return (
    <Stack sx={{ gap: 0, flex: 1 }}>
      <Stack
        sx={{
          minHeight: 350,
          backgroundColor: '#262626',
          justifyContent: 'center',
          placeItems: 'center'
        }}
      >
        <Text color={'#FFF'} size={35} weight={500}>
          About Us
        </Text>
      </Stack>
      <Stack
        sx={{
          flex: 1,
          backgroundColor: '#FFF',
          padding: '40px 20px',
          placeItems: 'center'
        }}
      >
        <Stack
          sx={{ width: '100%', maxWidth: 900, textAlign: 'center', gap: 40 }}
        >
          <Text size={18} weight={700}>
            Being a solid closer means nothing if you do not have a steady
            stream of leads to close. For an employer, if you do not have leads,
            no matter how good your closers are, you cannot grow.
          </Text>
          <Divider />
          <Text>
            Daniel Timothy is developed to be the preferred tool for high volume
            B2B sales. By making it easy for business developers to create sales
            sequences, you can exponentially increase the amount of leads per
            day you generate. For companies that offer a solid service, and have
            a strong sales teams, Daniel Timothy is the perfect partner to keep
            your closers closing by making it easy for them to flood their sales
            funnels.
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AboutView;
