import React from 'react';
import {
  ActionIcon,
  Group,
  Image,
  Footer as MantineFooter,
  Stack,
  Text
} from '@mantine/core';
import { Link } from 'react-router-dom';
import {
  BrandFacebook,
  BrandInstagram,
  BrandLinkedin,
  BrandTwitter
} from 'tabler-icons-react';
import { NAV_LINKS } from './Links';
import { mq } from '../../../config/theme';
import dtleadsLogo from '../../../images/dtleads-logo.png';

const Footer = () => {
  return (
    <MantineFooter
      fixed={false}
      height={[450, 450, 120, 80]}
      sx={{ flex: 1, position: 'relative', padding: '5px 20px' }}
    >
      <Stack sx={{ flex: 1, padding: 10 }}>
        <Group
          sx={mq({
            maxWidth: 1100,
            width: '100%',
            alignSelf: 'center',
            gap: [20, 20, 20, 100],
            justifyContent: 'space-between',
            flexDirection: ['column', 'column', 'row'],
            placeItems: ['start', 'start', 'center']
          })}
        >
          <Group
            component={Link}
            sx={{ gap: 5, color: '#000', textDecoration: 'none' }}
            to="/"
          >
            <Image height={50} src={dtleadsLogo} width={50} />
            <Text size={24} weight={500}>
              Daniel Timothy
            </Text>
          </Group>

          <Group
            sx={mq({
              flexDirection: ['column', 'column', 'row'],
              alignItems: ['start', 'start', 'center']
            })}
          >
            {NAV_LINKS.public.map(link => (
              <Text
                color="grey"
                component={link.to ? Link : 'a'}
                href={link.href}
                key={link.key}
                to={link.to}
                weight={500}
              >
                {link.label}
              </Text>
            ))}
          </Group>

          <Group>
            <ActionIcon
              color="dark"
              component="a"
              href="https://www.facebook.com/Daniel-Timothy-Leads"
              radius={'xl'}
              target="_blank"
            >
              <BrandFacebook />
            </ActionIcon>

            <ActionIcon
              color="dark"
              component="a"
              href="https://www.instagram.com/danieltimothyleads/"
              radius={'xl'}
              target="_blank"
            >
              <BrandInstagram />
            </ActionIcon>

            <ActionIcon
              color="dark"
              component="a"
              href="https://twitter.com/DTLeads"
              radius={'xl'}
              target="_blank"
            >
              <BrandTwitter />
            </ActionIcon>

            <ActionIcon
              color="dark"
              component="a"
              href="https://www.linkedin.com/company/danieltimothyleads/"
              radius={'xl'}
              target="_blank"
            >
              <BrandLinkedin />
            </ActionIcon>
          </Group>
        </Group>
      </Stack>
    </MantineFooter>
  );
};

Footer.propTypes = {};

export default Footer;
