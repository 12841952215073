import React from 'react';

const NAV_LINKS = {
  public: [
    {
      key: 'home',
      label: 'HOME',
      to: '/',
      icon: <></>,
      isSelected: pathname => pathname === '/'
    },
    {
      key: 'pricing',
      label: 'PRICING',
      to: '/contact-us',
      icon: <></>,
      isSelected: pathname => pathname === '/pricing'
    },
    {
      key: 'contact-us',
      label: 'CONTACT US',
      to: '/contact-us',
      icon: <></>,
      isSelected: pathname => pathname === '/contact-us'
    },
    {
      key: 'about',
      label: 'ABOUT',
      to: '/about',
      icon: <></>,
      isSelected: pathname => pathname === '/about'
    },
    {
      key: 'login',
      label: 'LOGIN',
      href: 'https://dashboard.danieltimothyleads.com/auth/login',
      icon: <></>,
      isSelected: pathname => pathname === '/login'
    },
    {
      key: 'register',
      label: 'REGISTER',
      href: 'https://dashboard.danieltimothyleads.com/auth/register',
      icon: <></>,
      isSelected: pathname => pathname === '/register'
    }
  ]
};

export { NAV_LINKS };
